import { Card, Image, Text, Badge, Button, Group } from '@mantine/core';
import { Timeline, Container, Divider, Spoiler } from '@mantine/core';


export default function SuperMSG() {
    return (
        // <Container size={700} px={0}>
        <Card shadow="xl" p="xl" radius="lg" withBorder>
            <Card.Section>
                <Image
                src="https://LandscapePapers.b-cdn.net/portfoilioSite/SMGBannerPhotoFrames.jpg"
                height={420}
                alt=""
                />
            </Card.Section>

            <Group position="apart" mt="lg" mb="xl">
                <Text weight={500} size="xl">Super Messaging</Text>
                <Group position="right">
                {/* <Text weight={500}>Norway Fjord Adventures</Text> */}
                <Badge color="blue" variant="outline">Swift</Badge>
                <Badge color="violet" variant="outline">SwiftUI</Badge>
                <Badge color="orange" variant="outline">Firebase</Badge>
                <Badge color="green" variant="outline">NodeJs</Badge>
                </Group>
            </Group>

                {/* <Container size={800} px={0}> */}
            <Text size="xl" color="blue" align='left'>
                Are you tired of sending and seeing messages that all look the same? With Super Messaging, you can create and share custom message bubble styles that you can use in your conversations in the iMessage app and then <b>edit</b> and <b>delete</b> your messages after they've been sent. These custom message bubbles appear right in the transcript alongside all other iMessages. 
            </Text>
            <Divider my="lg" />
            <Text size="xl" align='left'>
                The most technically challenging part of this project was implementing message editing and deleting in a secure and cheaply scalable way. I ended up with an elegant solution that checked both of those boxes. All message edits are end-to-end encrypted and, in most cases, remote database entries only need to be made when a user edits a message not when a message is originally sent. 
            </Text>

            {/* Button to view on app store */}
            <Button variant="light" color="blue"  mt="lg" radius="md" component='a' href='https://apps.apple.com/app/apple-store/id1510352128?app=messages' target="_blank">
                View in App Store
            </Button>
        </Card>
        // </Container>
    )
}