import { Card, Image, Text, Badge, Button, Group, Grid } from '@mantine/core';
import { Timeline, Container, Divider, Spoiler, Avatar} from '@mantine/core';
import { useEffect, useState } from 'react';
import GitHubButton from 'react-github-btn'

export default function PersonalProfCard() {

    useEffect(() => {
        const script = document.createElement('script');
    
        script.src = 'https://platform.linkedin.com/badges/js/profile.js';
        script.async = true;
        script.defer = true;
    
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        };
      }, []);

    return (
        // <Container size={700} px={0}>
        <Card shadow="xl" p="xl" radius="xl" withBorder>
            
                {/* <Avatar
                src="https://LandscapePapers.b-cdn.net/portfoilioSite/FullSizeRender%203.JPG"
                size='lg'
                radius='xl'
                /> */}

                <Text color="blue"><h1>Michael Baron</h1></Text>
                <h2>Broadly educated, empathetic programmer with 5+ years of programming experience.</h2>
                {/* links to linked in and github with proper formating*/}
                <Grid justify="space-around" align="center">
                <Grid.Col md={12} lg={3}>
                <div
                    class="badge-base LI-profile-badge"
                    data-locale="en_US"
                    data-size="medium"
                    data-theme="light"
                    data-type="VERTICAL"
                    data-vanity="michael-andrew-baron"
                    data-version="v1"
                >
                {/* <a href="https://www.linkedin.com/in/michael-andrew-baron" target="_blank" rel="noreferrer">LinkedIn</a> */}
                </div>
                </Grid.Col>
                <Grid.Col md={12} lg={3}>
                {/* <GitHubButton href="https://github.com/n4226">Github Account</GitHubButton> */}
                {/* <a href="https://github.com/n4226" target="_blank" rel="noreferrer">Github</a> */}
                <Button variant="outline" color="blue"  mt="lg" radius="md" component='a' href='https://github.com/n4226' target="_blank" rel="noreferrer">Github</Button>
                </Grid.Col>
                </Grid>
                {/* <div>
                    <h1>Overview</h1>
                    <h2>Skills and bref summer here</h2>
                </div>
                <div>
                    <h3>Experence and Projects</h3>
                    <p>Scroll for an interactive list</p>
                </div> */}

            {/* <Group position="apart" mt="lg" mb="xl">
                <Text weight={500} size="xl">Super Messaging</Text>
                <Group position="right">
                <Badge color="blue" variant="outline">Swift</Badge>
                <Badge color="violet" variant="outline">SwiftUI</Badge>
                <Badge color="orange" variant="outline">Firebase</Badge>
                <Badge color="green" variant="outline">NodeJs</Badge>
                </Group>
            </Group> */}

        </Card>
        // </Container>
    )
}