//imoprt use effect
import { useEffect } from 'react';

import SunriseProjectCard from './sunriseProjectCard';
import SuperMSG from './superMSG';
import LandscapePapers from './landscapePapers';
import PersonalProfCard from './personalProfCard';

import {Group, Grid, Container} from '@mantine/core';

export default function MainPage() {

    //run this code when the page loads
    useEffect(() => {
        /* ideas
            as scorlling, cards go up but are in a 3d layout 
            and camera routates and mores a little (not just moving up and dsown with sscrolling)

            card for each major project with linkts etc

            timelines where apropriate
        */

        // const [red, green, blue] = [69, 111, 225]
        // const section1 = document.querySelector('.section1')

        // window.addEventListener('scroll', () => {
        // let y = 1 + (window.scrollY || window.pageYOffset) / 150
        // y = y < 1 ? 1 : y // ensure y is always >= 1 (due to Safari's elastic scroll)
        // const [r, g, b] = [red/y, green/y, blue/y].map(Math.round)
        // section1.style.backgroundColor = `rgb(${r}, ${g}, ${b})`
        // })
    });

    return (
        // <div style={{height: '400vh'}} className='section1'>
           
        // </div>
        <div>
            <Container mt="xl" mb="xl">
                <PersonalProfCard />
            </Container>

            <div className='mainPage'>{/* list of projects */}
                {/* <Group position="center"> */}
                    <SunriseProjectCard />
                    <Grid justify="space-around" align="flex-start">
                    <Grid.Col md={8} lg={6} >
                        <SuperMSG />
                    </Grid.Col>
                    <Grid.Col md={8} lg={6} >
                        <LandscapePapers />
                    </Grid.Col>
                    </Grid>
                {/* </Group> */}
            </div>
        </div>
    )
}