import { Card, Image, Text, Badge, Button, Group } from '@mantine/core';
import { Timeline, Container, Divider, Spoiler } from '@mantine/core';


export default function LandscapePapers() {
    return (
        // <Container size={700} px={0}>
        <Card shadow="xl" p="xl" radius="lg" withBorder>
            <Card.Section>
                <Image
                src="https://LandscapePapers.b-cdn.net/portfoilioSite/LandscapesBannerPhotoFrames.jpg"
                height={420}
                alt="Norway"
                />
            </Card.Section>

            <Group position="apart" mt="lg" mb="xl">
                <Text weight={500} size="xl">Landscape Papers</Text>
                <Group position="right">
                {/* <Text weight={500}>Norway Fjord Adventures</Text> */}
                <Badge color="blue" variant="outline">Swift</Badge>
                <Badge color="violet" variant="outline">SwiftUI</Badge>
                <Badge color="pink" variant="outline">Next.js</Badge>
                <Badge color="lime" variant="outline">React</Badge>
                </Group>
            </Group>

                {/* <Container size={800} px={0}> */}
            <Text size="xl" color="blue" align='left'>
            Landscape Papers has a large collection of beautiful landscape and nature wallpapers. You can browse the full catalog or just catch the daily featured photos 
            </Text>
            <Divider my="lg" />
            <Text size="xl" align='left'>
            For this project, I not only created the app and website frontend, but also the required backend for allowing anyone to create an account, upload photos and submit them for review. In the future photographers will be paid a portion of their ad revenue through Stripe Connect. I also had to use a CDN to economically scale distributing large image files. 
            </Text>

            {/* Button to view on app store */}
            <Group grow position="apart" mt="md">
            <Button variant="light" color="blue"  mt="lg" radius="md" component='a' href='https://apps.apple.com/app/apple-store/id1629433409' target="_blank">
                View in App Store
            </Button>
            {/* button to view website */}
            <Button variant="outline" color="blue"  mt="lg" radius="md" component='a' href='https://landscapepapers.com' target="_blank">
                View Website
            </Button>
            </Group>
        </Card>
        // </Container>
    )
}