import logo from './logo.svg';
import './App.css';
import MainPage from './mainPage/mainPage';
import { MantineProvider, Text } from '@mantine/core';

import { useEffect, useState } from 'react';
import { ColorSchemeProvider, ColorScheme } from '@mantine/core';
import { useColorScheme } from '@mantine/hooks';


function App() {

   // hook will return either 'dark' or 'light' on client
  // and always 'light' during ssr as window.matchMedia is not available
  const preferredColorScheme = useColorScheme();
  const [colorScheme, setColorScheme] = useState(preferredColorScheme);
  const toggleColorScheme = (value) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  // useEffect(() => {
  //   const preferredColorScheme = useColorScheme();
  //   setColorScheme(preferredColorScheme);
  // }, []);

  console.log(colorScheme);
  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
    <MantineProvider theme={{ colorScheme }} withGlobalStyles withNormalizeCSS>
    <div className="App">
      <MainPage />
    </div>
    </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
