import { Card, Image, Text, Badge, Button, Group, Grid, Stack, Space } from '@mantine/core';
import { Timeline, Container, Divider, Spoiler } from '@mantine/core';
import { useRef, useState, useEffect } from 'react';


function MainDescCard() {
    return (
        <Card shadow="xl" p="xl" radius="lg" withBorder>
        <Card.Section>
            <div className="sunriseImgOverlay">
            <Image
            src="https://LandscapePapers.b-cdn.net/portfoilioSite/still2.jpg"
            height={420}
            alt=""
            />
            <Stack align="center" justify="flex-end" spacing="lg">
            {/* <Space h="sm" /> */}
                <Button  color="blue"  mt="lg" radius="md" component='a' href='https://docs.google.com/document/d/141NHjCvqbJNso96KaKDWrYno2Pg51uMNrYQ6z-2FZlE/edit' target="_blank">
                    Watch Demo Videos
                </Button>
            </Stack>
            </div>
        </Card.Section>

        <Group position="apart" mt="lg" mb="xl">
            <Text weight={500} size={24}>Sunrise Graphics Engine</Text>
            <Group position="right">
            <Badge color="grape" variant="fill">60k+ lines of C++</Badge>
            <Badge color="red" variant="outline">Vulkan</Badge>
            <Badge color="yellow" variant="outline">Metal</Badge>
            <Badge color="blue" variant="outline">Swift</Badge>
            <Badge color="orange" variant="outline">GLSL</Badge>
            </Group>
        </Group>

            {/* <Container size={800} px={0}> */}
        <Text size="xl" color="blue" align='left'>
            A one of a kind, high-performance, multi-window, multi-monitor, and multi-GPU graphics engine created with a primary use of rendering the Earth with 3D-meshes procedurally generated from 2D-mapping data at hundreds of frames per second.
        </Text>
            {/* </Container> */}

        <Divider my="lg" />
        {/* split columns */}
        {/* <Group grow position="apart" mt="md" mb="xs"> */}
            
        <Spoiler maxHeight={120} title="Description" color="blue" mt="md" mb="xs" showLabel="Read More" hideLabel="Hide">
        <Text size="lg" align='left'>
            With more than 10,000 hours to date, this is by far the most <b>technically challenging</b> project I have ever taken on but also the most fun. I could go in depth for pages and pages on all the technical challenges I had to overcome. In short, this project has been challenging for the following overarching reasons:
            {/* <Text size="md"> */}
            <ul>
                <li>In order to get every bit of performance out of the hardware, I had to remove all non-essential abstractions meaning I had to think at a very low level when developing the entire engine.</li>
                <li>To be more performant than all existing options, I had to make a graphics engine in very different ways. This meant that all debugging tools (Vulkan Validation Layers, NVidia NSigh Graphics, RenderDoc, Metal Frame Debugger) gave me countless errors and crashes. In fact I could never choose which debugging tool I like best, I just had to use whichever one did not crash that day (if any). This caused a lot of work to have to be done “blind” with no debugging tool.</li>
                <li>I believe I am one of the first to create a realtime graphics engine, to render massive scenes to multiple monitors distributing the workload across multiple heterogeneous GPUs. I have begun to run up against the limits of the Vulkan API more and more. No debugging tools work with multiple GPUs: not even the vulkan validation layers.</li>
            </ul>
            {/* </Text> */}
        </Text>
        </Spoiler>
            {/* <Divider my="sm" /> */}

            
                
        {/* </Group> */}

        </Card>
    )
}


export default function SunriseProjectCard() {
    // return (
    //     <div>{/* project 1 - Sunrise Graphics Engine */}
    //         <h1>Sunrise Graphics Engine</h1>
    //         <h2>3D Graphics Engine</h2>
    //     </div>
    // )

    //make state for if timeline is expanded
    // const [isExpanded, setIsExpanded] = useState(false);
    // const [isInner1Expanded, setIsInner1Expanded] = useState(false);
    // const [isInner2Expanded, setIsInner2Expanded] = useState(false);

    
    const spoilerControlRef = useRef(null);
    // //when the spoiler control ref is pushed, update the isExpanded state
    // useEffect(() => {
    //     if (spoilerControlRef.current) {
    //         spoilerControlRef.current.addEventListener('click', () => {
    //             setIsExpanded(!isExpanded);
    //         });
    //     }
    // }, [spoilerControlRef.current]);

    // //when the isExpanded state changes, programmatically click the spoiler control ref
    // useEffect(() => {
    //     if (spoilerControlRef.current) {
    //         spoilerControlRef.current.click();
    //     }
    // }, [isExpanded]);

    const innerSpoilerControlRef1 = useRef(null);
    const innerSpoilerControlRef2 = useRef(null);

    // //when either inner spoiler control ref 1 is clicked, update the isExpanded state
    // useEffect(() => {
    //     if (innerSpoilerControlRef1.current) {
    //         innerSpoilerControlRef1.current.addEventListener('click', () => {
    //             //if will be expanded, expand the outer spoiler
    //             if (!isInner1Expanded) {
    //                 setIsExpanded(true);
    //             }
    //             setIsInner1Expanded(!isInner1Expanded);
    //         });
    //     }
    // }, [innerSpoilerControlRef1.current]);

    // //when either inner spoiler control ref 2 is clicked, update the isExpanded state
    // useEffect(() => {
    //     if (innerSpoilerControlRef2.current) {
    //         innerSpoilerControlRef2.current.addEventListener('click', () => {
    //             //if will be expanded, expand the outer spoiler
    //             if (!isInner2Expanded) {
    //                 setIsExpanded(true);
    //             }
    //             setIsInner2Expanded(!isInner2Expanded);
    //         });
    //     }
    // }, [innerSpoilerControlRef2.current]);
    

    return (
        <div className='projectHolder'>{/* project 1 - Sunrise Graphics Engine */}
        {/* <Group position="center" mt="md" mb="xs"> */}
        <Grid justify="center" align="flex-start">
            {/* <Container size={400}> */}
            <Grid.Col md={10} lg={6}>
            <MainDescCard />
            </Grid.Col>

            <Grid.Col md={10} lg={4}>
            <Spoiler  controlRef={spoilerControlRef}  maxHeight={800} title="Sunrise Graphics Engine" color="blue" mt="xl" mb="xl" showLabel="Full Timeline" hideLabel="Hide">
            <Timeline className='projTimeline'  active={4}>
                <Timeline.Item title="August 2018 - Initial Idea">
                I noticed that all the current flight simulation offerings were not nearly performant enough especially for multiple monitors (which are required for physical flight simulators) and none supported using multiple GPUs to render to multiple monitors from one computer.
                </Timeline.Item>
                <Timeline.Item title="Fall 2018 - Spring 2019: Unity">
                    I started by working in Unity for a few months learning many things about 3D in general and dealing with 3D scenes at a globe scale (Transformations, Quaternions, LOD switching, Shaders, Floating Origins, Quad Tree Spheres, etc), but, eventually, I realized I could never get the performance I needed from an existing game/graphics engine.
                </Timeline.Item>
                <Timeline.Item title="Summer 2019 - Fall 2020: MacOS and Metal">
                    <Spoiler controlRef={innerSpoilerControlRef1} maxHeight={110} title="MacOS and Metal" color="blue" mt="md" mb="xs" showLabel="See all the technical Details" hideLabel="Hide">
                    I then transitioned to making a complete graphics engine for MacOS in Metal. However, after months of work, I realized that Metal had far too many bugs and it became nonviable to continue using it for the project.
                    <br />
                    <br />
                    Since I knew Apple’s Metal graphics API had recently been updated with features that allowed “GPU-Driven Render Pipelines”, and I had a lot of experience developing programs with Swift in Xcode I decided to start making my graphics engine there. However, after months of running into bug after bug - as soon as one bug was patched by Apple, another similar one would appear - And after I was lucky enough to speak with an Apple Metal engineer who, after looking over my code for an hour, concluded with me that I was doing nothing wrong,  I concluded Metal was just too unstable for my usage.
                    <br />
                    <br />
                    Just one example of the impossible bugs I ran into was that calling the function useResource and passing in a resource in a loop had different results from calling the related function useResources with an array of the same resources. This should not happen.
                    <br />
                    <br />
                    What Apple calls “GPU-Driven Rendering Pipelines” is a set of features that allow storing an entire scene on the gpu with gpu pointers and then issuing dynamic draw and kernel dispatch calls from gpu compute kernels. This is still slightly more advanced than Vulkan extensions allow. 
                    </Spoiler>


                </Timeline.Item>
                <Timeline.Item title="Fall 2020 - Present: Vulkan and C++">
                <Spoiler controlRef={innerSpoilerControlRef2} maxHeight={220} title="Vulkan and C++" color="blue" mt="md" mb="xs" showLabel="See all the technical Details" hideLabel="Hide">
                I rewrote my previous code in Vulkan and C++ while designing the architecture from the ground up to support multiple windows and multiple GPUs. I also created custom binary mesh and custom binary image file formats to store data in the same way as the engine does on the GPU. This allows much faster streaming from disk. I also created a separate program, with an optional UI, which generates the 3D-world terrain meshes with the engine. I have learned the lesson that one's development is only as fast as one can debug! 
                <br />
                <br />
                I created the engine intending for it to be as composable as possible. It not only has the concept of a scene (which defines what will be rendered) like numerous game engines have, but it also has the concept of a scene render coordinator which defines how a scene is rendered. The scene render coordinator defines the rendering process through several gpu passes and their dependencies. This allows for any low-level render workflow to be declaratively described in the engine without having to worry about manually adding resource and execution dependencies through Vulkan.
                </Spoiler>
                </Timeline.Item>
                <Timeline.Item title="Summer 2022 - Got Multi-GPU Functionality Working">
                After much  work, I was able to successfully get multiple GPUs to render to multiple monitors and with near linear FPS scaling. This is a big deal since this functionality, especially with heterogeneous GPUs, is almost, if not completely, nonexistent in any other real-time graphics engine. 
                </Timeline.Item>
                <Timeline.Item title="Release a Flight Simulator add-on">
                </Timeline.Item>
                <Timeline.Item title="Release the Engine">
                </Timeline.Item>
            </Timeline>
            </Spoiler>
            </Grid.Col>
            {/* </Container> */}
            {/* </Group> */}
        </Grid>
        </div>
    )
}
